<template>
  <div class="tjEditor tj-editor">
    <form @submit.prevent="createNote" role="form">
      <!-- text area with Add to note placeholder -->
      <div class="tj-editor__editor">
        <mavon-editor
          rows="6"
          id="note-input"
          placeholder="Add to note..."
          class="tj-editor__text"
          :showShortCut="false"
          v-model="customNote"
          :language="'en'"
          :subfield="false"
          :toolbars="mavonToolbar"
          @save="createNote"
        />
        <div class="tj-editor__notification">
          <span
            class="spinner-border spinner-border-sm ml-2 tj-editor__notification--icon"
            :style="{ opacity: isEddieLoading ? 1 : 0 }"
          ></span>
          <span
            class="tj-editor__notification--title"
            :style="{ opacity: eddieNotification.length > 0 ? 1 : 0 }"
            >Eddie is loading your content</span
          >
        </div>
      </div>
      <div class="d-grid mt-2">
        <button class="ed-btn__blim ed-btn__sm" type="submit">
          <span v-if="!isLoading"> Add note </span>
          <span
            v-else
            class="spinner-border spinner-border-sm ml-2"
            :style="{ opacity: isLoading ? 1 : 0 }"
          ></span>
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import moment from 'moment';
import mavenEditorConfigs from '@/config/mavenEditor';

export default {
  data() {
    return {
      customNote: '',
      isLoading: false,
      mavonToolbar: mavenEditorConfigs.mavonToolbar,
      isEddieStarted: false,
      isEddieEnded: false,
      isEddieLoading: false,
      eddieNotification: '',
      eddieQuery: '',
    };
  },
  watch: {
    customNote(value) {
      const text = value.toLowerCase();
      if (text.includes('/eddie')) {
        this.isEddieStarted = true;
        this.eddieNotification = 'Eddie call started';
      }
      if (text.includes('eddie/')) {
        this.isEddieEnded = true;
        this.eddieNotification = 'Eddie has started thinking';
      }

      if (this.isEddieStarted && this.isEddieEnded) {
        // Extract the text between "/eddie" and "eddie/"
        const startIndex = text.indexOf('/eddie') + 6; // Length of "/eddie"
        const endIndex = text.indexOf('eddie/');
        const queryText = text.slice(startIndex, endIndex).trim();
        this.eddieQuery = queryText;
        this.isEddieLoading = true;
        if (this.isEddieLoading) {
          console.log(this.isLoading);
          this.$store.dispatch('EditorAIFetchProcess', this.eddieQuery).then(() => {
            // const replacementText = await this.fetchInfoFromOpenAI(this.eddieQuery);
            const modifiedInput = this.replaceEddieContentByIndex(value, this.$store.state.editorAIFetch);
            console.log('xvf', modifiedInput);
            this.customNote = modifiedInput;
            this.isEddieLoading = false;
            this.isEddieStarted = false;
            this.isEddieEnded = false;
            this.eddieNotification = '';
            this.eddieQuery = '';
          });
          // setTimeout(async () => {
          // // Define a regular expression to match "/eddie...eddie/"
          //   // const replacementText = 'Yes they have a good relationship';
          //   // Use the `replace` method to replace the matched content with the replacementText
          // }, 100);
        }
      } else {
        // Reset the query if eddie is not both started and ended
        this.eddieQuery = '';
      }
    },
  },
  methods: {
    replaceEddieContentByIndex(input, replacementText) {
      // Find the index positions of "/eddie" and "eddie/"
      const eddieStartIndex = input.indexOf('/eddie');
      const eddieEndIndex = input.indexOf('eddie/');

      // Check if both markers are present and eddieEndIndex is greater than eddieStartIndex
      if (eddieStartIndex !== -1 && eddieEndIndex !== -1 && eddieEndIndex > eddieStartIndex) {
        // Extract the text before and after the markers
        const textBeforeEddie = input.slice(0, eddieStartIndex);
        const textAfterEddie = input.slice(eddieEndIndex + 6); // Add 6 to account for "eddie/"

        // Combine the extracted text with the replacementText
        const modifiedInput = textBeforeEddie + replacementText + textAfterEddie;

        return modifiedInput;
      }
      // If markers are not found or not in the correct order, return the original input
      return input;
    },
    createNote() {
      this.isLoading = true;
      const value = {
        note: this.customNote,
        noteUrl: 'thoughtjumper-client.netlify.app/',
        type: 'note',
        timestamp: moment().format(),
      };

      this.$store.dispatch('addToNote', value).then(() => {
        this.customNote = '';
        this.isLoading = false;
      });
    },
    // createNote() {
    //   console.log('xvf', this.eddieQuery);
    // },
  },
};
</script>

<style lang="scss" scoped>
// .tjEditor {
//   width: 100%;
//   height: 100%;
//   z-index: 0;

//   form {
//     width: 100%;
//     height: 100%;
//   }
//   .mavonEditor {
//     width: 100%;
//     height: 92%;
//     box-shadow: none!important;;
//   }
//   .button-div {
//     height: 7%;
//   }
// }

.tj-editor {
  width: 100%;
  height: 100%;
  z-index: 0;

  form {
    width: 100%;
    height: 100%;
  }

  &__editor {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
    border-radius: 0.4rem;
  }
  &__text {
    width: 100%;
    height: 92%;
    box-shadow: none !important;
  }
  &__notification {
    padding: 0.4rem;
    border-top: 1px solid rgba(0, 0, 0, 0.15);
    display: flex;
    margin: 0 1rem;
    color: var(--color-grey-1);
    align-items: center;

    &--icon {
      margin-right: 0.5rem;
      margin-left: auto;
    }
  }
  .button-div {
    height: 7%;
  }
}

/* Hide the preview panel */
// .mavon-editor .mavon-preview {
//   display: none !important;
// }

// /* Expand the editor to fill the full width */
// .mavon-editor .mavon-editor-content {
//   width: 100% !important;
// }
</style>
